import * as React from 'react';
import tw, { styled } from 'twin.macro';

import Layout from '../layouts/layout';
import BlogCard from '../components/blog-card';
import SocialShareButtons from '../components/socialShareButtons';

export const BlogDetailStyled = styled.section`
  ${tw`block relative`}

  .content-section {
    ${tw`mb-28 xl:mb-36 mt-14 md:mt-20 xl:mt-28 relative`}

    .social-share-wrp {
      ${tw`absolute top-0`}
      z-index: 10;
      left: -30px;

      .share-items {
        ${tw`flex flex-col items-center justify-center bg-white py-3 md:py-8`}
        width: 27px;
        box-shadow: 0px 0px 6px #00000029;
        border-radius: 31px;

        @media (min-width: 768px) {
          width: 62px;
        }

        .share-item {
          ${tw`mb-7`}

          &:last-child {
            ${tw`mb-0`}
          }

          a {
            ${tw`block`}

            &:hover {
              ${tw`opacity-70`}
            }
          }

          &.facebook {
            svg {
              width: 9px;

              @media (min-width: 768px) {
                width: auto;
              }
            }
          }

          &.twitter {
            svg {
              width: 11px;

              @media (min-width: 768px) {
                width: auto;
              }
            }
          }

          &.linkedin {
            svg {
              width: 10px;

              @media (min-width: 768px) {
                width: auto;
              }
            }
          }
        }
      }
    }

    .title-wrp {
      ${tw`text-center mb-16 xl:mb-20`}

      h2 {
        ${tw`text-4xl md:text-6xl mb-2 xl:mb-4 text-primary-dark font-bold mx-auto`}
        line-height: 40px;
        max-width: 964px;

        @media (min-width: 768px) {
          line-height: 60px;
        }
      }

      .date {
        ${tw`md:text-xl lg:text-2xl`}
      }
    }
    .content-detail-section {
      img {
        width: calc(100% + 72px);
        max-width: initial;
        margin-left: -36px;
        ${tw`my-12 xl:my-16`}

        @media (min-width: 1280px) {
          ${tw`ml-0`}
          width: 100%;
        }
      }

      p {
        ${tw`mb-7 leading-7 md:leading-9 text-gray-dark md:text-xl`}
      }

      > * :not(img) {
        ${tw`xl:px-24`}
      }
    }
  }
`;

export const SectionHeaderStyled = styled.div`
  ${tw`flex flex-col md:flex-row md:justify-between relative mb-7`}

  h2 {
    ${tw`mb-3 text-primary-dark`}
  }

  .filters {
    ${tw`font-normal text-primary-dark`}
  }
`;

// markup
const BlogDetailPage = () => {
  return (
    <Layout>
      <main>
        <title>Common Page</title>
        <BlogDetailStyled className="ves-section">
          <div className="ves-container">
            <div className="content-section">
              <SocialShareButtons />

              <div className="title-wrp">
                <h2>We start with the customer experience and work back toward the technology.</h2>
                <span className="date">November 17, 2020</span>
              </div>
              <div className="content-detail-section">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis velit
                  metus. Nulla sit amet exposuere, efficitur velit id, aliquet nunc. Ut pulvinar
                  congue consectetur. Quisque molestie odio elit, ut fermentum massa porttitor ut.
                  Fusce id lorem massa. Fusce viverra ipsum in ligula tempus, at accumsan lectus
                  tempus. Nulla cursus, ex vitae finibus fermentum, libero lacus rutrum neque, a
                  dictum lacus mauris pretium tortor. Aliquam rhoncus nunc erat, cursus feugiat nisl
                  vestibulum vulputate. Nunc sed metus sed ante fermentum tincidunt. Aliquam erat
                  volutpat. Ut vel est mollis, dictum mauris id, ultrices lacus.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis velit
                  metus. Nulla sit amet ex posuere, efficitur velit id, aliquet nunc. Ut pulvinar
                  congue consectetur. Quisque molestie odio elit, ut fermentum massa porttitor ut.
                  Fusce id lorem massa. Fusce viverra ipsum in ligula tempus, at accumsan lectus
                  tempus. Nulla cursus, ex vitae finibus fermentum, libero lacus rutrum neque, a
                  dictum lacus mauris pretium tortor. Aliquam rhoncus nunc erat, cursus feugiat nisl
                  vestibulum vulputate. Nunc sed metus sed ante fermentum tincidunt. Aliquam erat
                  volutpat. Ut vel est mollis, dictum mauris id, ultrices lacus. Nullam volutpat
                  purus et neque pellentesque volutpat. Quisque scelerisque, lectus at maximus
                  luctus, nunc nulla tincidunt nibh, at sagittis nisl tellus et lorem. Donec sit
                  amet hendrerit sapien. Morbi varius, ligula ut aliquam ultrices, erat urna
                  pulvinar odio, quis ultricies nisl tortor eu velit. Ut a nisl ex. Nulla sit amet
                  varius lectus. Nam vel quam pretium, ornare diam condimentum, iaculis elit.
                </p>
                <img src="/blog-detail.png" alt="Blog Detail" />
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis velit
                  metus. Nulla sit amet ex posuere, efficitur velit id, aliquet nunc. Ut pulvinar
                  congue consectetur. Quisque molestie odio elit, ut fermentum massa porttitor ut.
                  Fusce id lorem massa..
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis velit
                  metus. Nulla sit amet ex posuere, efficitur velit id, aliquet nunc. Ut pulvinar
                  congue consectetur. Quisque molestie odio elit, ut fermentum massa porttitor ut.
                  Fusce id lorem massa. Fusce viverra ipsum in ligula tempus, at accumsan lectus
                  tempus. Nulla cursus, ex vitae finibus fermentum, libero lacus rutrum neque, a
                  dictum lacus mauris pretium tortor. Aliquam rhoncus nunc erat, cursus feugiat nisl
                  vestibulum vulputate. Nunc sed metus sed ante fermentum tincidunt. Aliquam erat
                  volutpat. Ut vel est mollis, dictum mauris id, ultrices lacus.
                </p>
              </div>
            </div>

            <SectionHeaderStyled>
              <h2 className="h2">Related Stories</h2>
            </SectionHeaderStyled>

            <div className="grid  md:grid-cols-3 gap-4 md:gap-y-16">
              <BlogCard
                date="November 13, 2020"
                title="Credibility, Trust and Confidence"
                description="Hilton has a strong global commitment to sustainability. It is therefore not surprising Hilton Fiji"
                link="#123"
              />
              <BlogCard
                date="November 13, 2020"
                title="Credibility, Trust and Confidence"
                description="Hilton has a strong global commitment to sustainability. It is therefore not surprising Hilton Fiji"
                link="#123"
              />
              <BlogCard
                date="November 13, 2020"
                title="Credibility, Trust and Confidence"
                description="Hilton has a strong global commitment to sustainability. It is therefore not surprising Hilton Fiji"
                link="#123"
              />
            </div>
          </div>
        </BlogDetailStyled>
      </main>
    </Layout>
  );
};

export default BlogDetailPage;
